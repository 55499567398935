<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div data-aos="fade-in" data-aos-easing="linear" data-aos-duration="1500">
    <div class="relative">
      <div class="absolute inset-0">
        <img
          class="w-full h-full object-cover"
          src="https://cdn.pixabay.com/photo/2016/08/11/22/12/door-1587023_960_720.jpg"
          alt=""
        />
        <div
          class="absolute inset-0 bg-gray-700"
          style="mix-blend-mode: multiply"
          aria-hidden="true"
        />
      </div>
      <div
        class="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8"
      >
        <h1
          class="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl"
          data-aos="flip-up"
          data-aos-easing="linear"
        >
          Sectors.
        </h1>
        <p
          class="mt-6 text-xl text-indigo-100 max-w-3xl"
          data-aos="fade-in"
          data-aos-easing="linear"
        >
          Commercial, Residential, Hospitality, Pharmaceutical & Healthcare,
          Public.
        </p>
      </div>
    </div>
    <com-res-hos />
    <phar-heal-pub />
    <SectorsBody />
    <ContactBar />
  </div>
</template>

<script>
import SectorsBody from "../components/pagebody/SectorsBody.vue";
import ContactBar from "../components/other/ContactBar.vue";

export default {
  name: "About",
  components: {
    ContactBar,
    SectorsBody,
  },
};
</script>
