<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="py-16 bg-gray-50 overflow-hidden">
    <div class="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
      <div class="text-base max-w-prose mx-auto lg:max-w-none">
        <h2
          class="text-base text-primary font-semibold tracking-wide uppercase"
        >
          Kirby Brothers
        </h2>
        <p
          class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
        >
          Sectors of Business
        </p>
      </div>

      <div
        class="relative z-10 text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72"
      >
        <p class="text-lg text-gray-900">
          Over the last 30+ years Kirby Brothers has been working at the highest
          level in the construction industry, our knowledge and expertise
          stretches across all sectors.
        </p>
      </div>
      <div class="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
        <div class="relative z-10">
          <div class="prose prose-indigo text-gray-500 mx-auto lg:max-w-none">
            <p class="mt-4 text-lg text-gray-900">
              Our team is specifically trained to cater to the variety of needs
              & environments on every project we take on. Our experienced team
              gives us the capability to deliver large projects, at the highest
              standard of finish on time, every time, ensuring customer
              satisfaction across all sectors.
            </p>
              <p class="mt-4 text-lg text-gray-900">
                <span class="font-bold">List of Sectors:</span>
                <ul>
                  <li>Commercial</li>
                  <li>Residential</li>
                  <li>Hospitality</li>
                  <li>Pharma & Healthcare</li>
                  <li>Public</li>
                </ul>
              </p>
          </div>
        </div>
          <div class="space-y-4">
                <div class="aspect-w-3 aspect-h-2">
                  <img
                    class="object-cover shadow-lg rounded-lg"
                    data-aos="fade-up"
                    src="https://images.unsplash.com/photo-1601661862050-3186484ca3ad?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1875&q=80"
                    alt=""
                  />
                </div>
              </div>
            </div>
        </div>
      </div>


  <!-- BREAK BREAK -->
  <div
    class="relative bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8"
  >
    <div class="absolute inset-0">
      <div class="bg-white h-1/3 sm:h-2/3" />
    </div>
    <div class="relative max-w-7xl mx-auto">
      <div class="text-center">
        <h2
          class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl"
        >
          List of Sectors.
        </h2>
      </div>
      <div
        class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:grid-rows-2 lg:max-w-none"
      >
        <div
          v-for="post in posts"
          :key="post.title"
          class="flex flex-col rounded-lg shadow-lg overflow-hidden"
              data-aos="fade-up"
        >
          <div class="flex-shrink-0">
            <img class="h-48 w-full object-cover" :src="post.imageUrl" alt="" />
          </div>
          <div class="flex-1 bg-white p-6 flex flex-col justify-between">
            <div class="flex-1">
              <p class="text-xl font-semibold text-gray-900">
                {{ post.title }}
              </p>
              <p class="mt-3 text-base text-gray-500">
                {{ post.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
             <div class="h-11"></div>
              <div class="mt-5 max-w-md mx-auto">
                  <router-link
                    to="/portfolio"
                    class="btg bh"
                  >
                    View Portfolio
                  </router-link>
                </div>
  </div>
</template>

<script>
const posts = [
  {
    title: "Commercial",
    href: "#",
    category: { name: "Article" },
    description:
      "We have vast experience in commercial projects ranging from office blocks, shopping centres, retail units & factories.",
    imageUrl:
      "https://cdn.pixabay.com/photo/2014/03/08/22/32/escalator-283448_960_720.jpg",
  },
  {
    title: "Residential",
    href: "#",
    category: { name: "Article" },
    description:
      "We’ve done a lot of business over the years with new build residential developments. As well as private clients in the residential sector, always delivering the highest standard work.",
    imageUrl:
      "https://cdn.pixabay.com/photo/2016/04/18/08/50/kitchen-1336160_960_720.jpg",
  },
  {
    title: "Hospitality",
    href: "#",
    category: { name: "Article" },
    description:
      "We’ve delivered an extensive list of projects within the hospitality industry over the years, Including Hotels, Restaurants, Bars & Many more.",
    imageUrl:
      "https://images.unsplash.com/photo-1590846406792-0adc7f938f1d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=632&q=80",
  },
  {
    title: "Pharma & Health Care",
    href: "#",
    category: { name: "Article" },
    description:
      "For the last 30+ years we have been involved in a broad range of Pharmaceutical & Healthcare projects! We specialise in delivering the highest standard of work & safety whilst on site & can provide special finishes for delicate work areas in this industry.",
    imageUrl:
      "https://cdn.pixabay.com/photo/2018/01/18/09/26/hospital-3089884_960_720.jpg",
  },
  {
    title: "Public",
    href: "#",
    category: { name: "Article" },
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.",
    imageUrl:
      "https://cdn.pixabay.com/photo/2019/12/05/05/05/art-gallery-4674319_960_720.jpg",
  },
];

export default {
  name: "SectorsBody",
  setup() {
    return {
      posts,
    };
  },
};
</script>
